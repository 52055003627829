// Utilities File

import { format } from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { t } from 'i18next'
import numbro from 'numbro'
import { Ein, State, User } from '../generated/graphql'

export function formatMoney(money: number): string {
  const format = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(Math.abs(money))
  return money < 0 ? `(${format})` : format
}

export function formatNegativeValues(number: number): string {
  return number < 0 ? `(${Math.abs(number)})` : String(number)
}

export function formatMoneyNoDecimals(money: number): string {
  const format = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  }).format(Math.abs(money))
  return money < 0 ? `(${format})` : format
}

export function formatPercentage(percentage: number, fractionDigits = 2): string {
  return `${(percentage * 100).toFixed(fractionDigits)}%`
}

export function formatNumber(value: number | string, mantissa?: number): string {
  if (typeof value === 'undefined' || value === null || (typeof value === 'string' && !value)) {
    return value
  }

  const format: numbro.Format = {
    thousandSeparated: true,
    negative: 'parenthesis',
    mantissa: mantissa ?? 0,
  }

  return numbro(value).format(format)
}

export function formatDateISOToCustom(
  dateISOString: string,
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' },
): string {
  const date = convertToUTC(dateISOString) as Date
  return formatDateToCustom(date, options)
}

export function formatStringDate(date: string, formatDate = 'MMM d, yyyy'): string {
  const parsedDate = Date.parse(date)
  return format(parsedDate, formatDate)
}

export function formatDateToCustom(
  date: Date,
  options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: '2-digit' },
): string {
  return date.toLocaleDateString('en-US', options)
}

export function removeItem<T>(array: T[], index: number): T[] {
  return [...array.slice(0, index), ...array.slice(index + 1)]
}

export function replace<T>(array: T[], item: T, index: number): T[] {
  return [...array.slice(0, index), item, ...array.slice(index + 1)]
}

export interface HasId<Id> {
  id: Id
}

export function hasSameId<Id, T extends HasId<Id>>(first: T, second: T): boolean {
  return first?.id === second?.id
}

export function debounce<Params extends unknown[]>(
  fn: (...args: Params) => unknown,
  delay = 300,
): (...args: Params) => void {
  let timeoutId: ReturnType<typeof setTimeout>

  return (...args: Params): void => {
    if (timeoutId) {
      clearTimeout(timeoutId)
    }

    timeoutId = setTimeout(() => {
      fn.apply(fn, args)
    }, delay)
  }
}

export interface HasName {
  name: string
}

export function getName<T extends HasName>(value: T): string {
  return value?.name ?? ''
}

export type Noop = () => void
export interface HasLabel {
  label: string
}

export function getLabel<T extends HasLabel>(value: T): string {
  return value.label
}

export function getOptionSelected<T extends HasLabel>(option: T, value: T): boolean {
  return getLabel(option) === getLabel(value)
}

export const EMPTY_ARRAY = [] as const

export function ordinal(number: number): string {
  const ordianlFormat: numbro.Format = { output: 'ordinal' }

  return numbro(number).format(ordianlFormat)
}

interface AddHelperTextParams {
  errorMessage: string
  value: string | null
  total: number
}

export function addHelperText(params: AddHelperTextParams): string {
  if (params.errorMessage) {
    return params.errorMessage
  }

  const { value, total } = params

  const valueLength = typeof value === 'string' ? value.length : '0'

  return t('someOfAll', { some: valueLength, all: total })
}

export function getCountrySelected<T extends HasName>(option: T, value: T): boolean {
  return getName(option) === getName(value)
}

export const stateValues = Object.values(State)

export function convertTaxYearToDate(taxYear: number): Date {
  const dateTaxYear = new Date()

  dateTaxYear.setFullYear(taxYear)

  return dateTaxYear
}

export function convertMSTtoUTC(mstDateString: string): Date {
  const utcDate = zonedTimeToUtc(mstDateString, 'America/Denver')
  return utcDate
}

export function convertToUTC(dateString: string | null): Date | null {
  if (!dateString) {
    return null
  }

  if (!dateString.endsWith('Z') && !/([+-]\d{2}:\d{2})$/.test(dateString)) {
    return new Date(dateString + 'Z')
  }

  return new Date(dateString)
}

export function downloadBlobToFile(blob: Blob, filename = 'report'): void {
  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(blob)
  link.setAttribute('download', `${filename}.pdf`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const getEinOptions = (users: User[]): Ein[] => {
  return users.map((user) => ({
    id: user.id,
    name: user.fullName,
  }))
}
export function deepEqual<T>(obj1: T, obj2: T): boolean {
  // Base case: If both objects are identical, return true.
  if (obj1 === obj2) {
    return true
  }

  // Check if both objects are instances of Date and compare their times.
  if (obj1 instanceof Date && obj2 instanceof Date) {
    return obj1.getTime() === obj2.getTime()
  }

  // Check if both objects are objects and not null.
  if (typeof obj1 !== 'object' || typeof obj2 !== 'object' || obj1 === null || obj2 === null) {
    return false
  }

  // Get the keys of both objects.
  const keys1 = Object.keys(obj1) as (keyof T)[]
  const keys2 = Object.keys(obj2) as (keyof T)[]

  // Check if the number of keys is the same.
  if (keys1.length !== keys2.length) {
    return false
  }

  // Iterate through the keys and compare their values recursively.
  for (const key of keys1) {
    if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
      return false
    }
  }

  // If all checks pass, the objects are deep equal.
  return true
}

export function getTodaysDate(): Date {
  return new Date(new Date().setHours(23, 59, 59, 999))
}

type NullableKeys<T> = {
  [P in keyof T]: T[P] extends null | undefined ? P : never
}[keyof T]

type OmitNullable<T> = Omit<T, NullableKeys<T>>
export function omitNullEmptyOrUndefined<T>(obj: T): OmitNullable<T> {
  const result: Partial<T> = {}
  for (const key of Object.keys(obj) as Array<keyof T>) {
    const value = obj[key]
    if (value !== null && value !== undefined) {
      if (typeof value === 'string' && value === '') {
        continue
      }
      result[key] = value
    }
  }
  return result as OmitNullable<T>
}

export function parseUrlParameters<Params extends { [key: string]: string | number | boolean }>(
  uri: string,
  params: Params,
): string {
  const keys = Object.keys(params)

  return keys.reduce((uri, parameterName) => {
    return uri.replace(`:${parameterName}`, String(params[parameterName]))
  }, uri)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(value: any): boolean {
  return value && typeof value === 'object' && !Array.isArray(value)
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNullOrUndefined(value: any): boolean {
  return value === null || value === undefined
}

export function deepClone<T>(obj: T): T {
  if (obj === null || typeof obj !== 'object') {
    return obj
  }

  if (Array.isArray(obj)) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const copy: any[] = []
    for (let i = 0; i < obj.length; i++) {
      copy[i] = deepClone(obj[i])
    }
    return copy as unknown as T
  }

  if (obj instanceof Date) {
    return new Date(obj.getTime()) as unknown as T
  }

  if (obj instanceof RegExp) {
    return new RegExp(obj) as unknown as T
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const copy: { [key: string]: any } = {}
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      copy[key] = deepClone(obj[key])
    }
  }

  return copy as T
}

export const truncate = (word: string, maxLength: number): string =>
  word.length > maxLength ? `${word.slice(0, maxLength)}...` : word

export function indexByKey<List, Key extends keyof List>(array: List[], key: Key): Record<Key, List> {
  return array.reduce((acc, item) => {
    const keyValue = String(item[key]) as Key
    acc[keyValue] = item

    return acc
  }, {} as Record<Key, List>)
}

export type GenericFunction = <InputArgs extends unknown[], ReturnValue>(...args: InputArgs) => ReturnValue

export function memoize<TargetFunction extends GenericFunction>(
  functionToMemoize: TargetFunction,
): TargetFunction {
  const cache = new Map<string, ReturnType<TargetFunction>>()

  return function (this: unknown, ...args: Parameters<TargetFunction>): ReturnType<TargetFunction> {
    const cacheKey = JSON.stringify(args)
    const cachedResult = cache.get(cacheKey)

    if (cachedResult !== undefined) {
      return cachedResult
    }

    const result = functionToMemoize.apply(this, args) as ReturnType<TargetFunction>
    cache.set(cacheKey, result)
    return result
  } as TargetFunction
}

export const fieldIsRequiredError = (fieldName: string): string => `The field "${fieldName}" is required`
